<template>
  <WeContainer v-if="ready" header="">
    <!-- List -->
    <WeTable
      v-bind:index="false"
      v-bind:data="erpList"
      v-bind:columns="columns"
      v-bind:actions="actions"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
    ></WeTable>
    <!-- List -->

    <WeModal
      v-if="showModal && detail"
      v-on:close="onClose"
      v-bind:title="detail.title + ' ERP Entegrasyon Ayarları'"
      v-bind:title-image="detail.logo"
    >
      <div slot="body">
        <template v-if="!modalLoading">
          <div
            class="custom-scrollbar pl-1 pr-3"
            style="max-height: 400px; overflow: auto"
          >
            <WeCard class="bg-light mb-3">
              <div class="row justify-content-around">
                <div class="col-12 col-md-auto">
                  <label class="custom-label">Durum</label>
                  <WeSwitch
                    v-bind:show-status="true"
                    v-model="detail.is_active"
                  />
                </div>
                <div class="col-12 col-md-auto">
                  <label class="custom-label">Test Modu</label>
                  <WeSwitch
                    v-bind:show-status="true"
                    v-model="detail.is_test"
                  />
                </div>
                <div class="col-12 col-md-auto">
                  <label class="custom-label">Varsayılan</label>
                  <WeSwitch
                    v-bind:show-status="true"
                    v-model="detail.is_default"
                  />
                </div>
              </div>
            </WeCard>
            <div
              class="col-12 mb-3 col-lg p-0"
              v-for="item in detail.elements"
              v-bind:key="item.name"
            >
              <WeRowInput
                v-bind:class="getClass(item)"
                v-bind:label="item.label"
                v-bind:form="item.form"
                v-bind:placeholder="item.placeholder || item.label"
                v-bind:show-asterix="item.required"
                size="sm"
                v-bind:value="getValue(item)"
                v-on:input="item.value = $event"
              />
            </div>
          </div>
          <div class="text-center mt-3">
            <WeSubmitButton
              v-bind:submit-status="submit"
              v-on:submit="onSubmit"
            />
          </div>
        </template>
        <template v-else>
          <div style="min-height: 250px; overflow: auto">
            <WeLoading />
          </div>
        </template>
      </div>
    </WeModal>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      submit: false,
      ready: false,
      modalLoading: false,
      showModal: false,
      detail: null,
      columns: [
        {
          name: "logo",
          icon: "fas fa-camera",
          type: "image_url",
          width: "5%",
        },
        { name: "title", th: "ERP", type: "string", width: "70%" },
        { name: "is_active", th: "Durum", type: "boolean" },
      ],
      actions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
      ],
    };
  },
  methods: {
    ...mapActions("erp", ["update", "getById", "isActive", "getList"]),
    onAction(data) {
      if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.modalLoading = true;
        this.detail = row;
        this.showModal = true;

        setTimeout(() => {
          this.getById({
            id: row.id,
            onSuccess: (data) => {
              this.detail = data;
            },
            onFinish: () => {
              this.modalLoading = false;
            },
          });
        }, 500);
      }
    },
    onClose() {
      this.clearItem();
    },
    clearItem() {
      this.showModal = false;
      this.detail = null;
      this.getErpList();
    },
    onSwitch(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.isActive({
          form: data,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.$toast.success("Durum Güncellendi");
            } else {
              this.$toast.error("Durum Güncellenemedi");
            }
          },
          onError: (error) => {
            this.$toast.error("Durum Güncellenemedi");
          },
        });
      }
    },
    onSubmit() {
      if (!this.validForm()) return;

      this.submit = true;
      this.update({
        erp: this.detail,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$toast.success("Kayıt Başarılı");
            this.clearItem();
          } else {
            this.$toast.error("Hatalı İşlem");
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    validForm() {
      let result = this.detail.elements.filter(
        (item) => item.required && !item.value
      ).length;

      if (result) {
        this.$toast.error("Lütfen zorunlu alanları doldurun");
        return false;
      } else {
        return true;
      }
    },
    getParent(parentName) {
      return this.detail.elements.find(
        (element) => parentName === element.name
      );
    },
    getClass(item) {
      if (item.hasOwnProperty("parent")) {
        const parentItem = this.getParent(item.parent);

        if (parentItem.form == "boolean") {
          if (!parentItem.value) {
            item.value = null;
            return "d-none";
          }
        }
      }
    },
    getValue(item) {
      let result = item.value;

      if (item.hasOwnProperty("parent")) {
        const parentItem = this.getParent(item.parent);

        if (result) {
          parentItem.value = true;
        }
      }

      return result;
    },
    getErpList() {
      this.ready = false;
      this.getList({
        onFinish: () => {
          this.ready = true;
        },
      });
    },
    checkPermission(code) {
      return permission.check("erpIntegration", code);
    },
  },
  computed: {
    ...mapState({
      erpList: (state) => state.erp.list,
    }),
  },
  mounted() {
    this.getErpList();
  },
};
</script>
